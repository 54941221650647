/* ------------------------------------------ Global Variables ------------------------------ */
// formerly: timeclock.js (in-case needed)

var enableAllSubmitButtons = true;
var constants = new Object();

g.baseApiUrl = "/api/private/v1";

/* ------------------------------------------ Ready ------------------------------ */

$(document).ready(function () {

  // hover class
  $("table.dataTable > tbody")
    .on("mouseover", "tr:visible:not(.hover)", function (e) {
      $(this).toggleClass("hover", true);
    })
    .on("mouseout", "tr:visible.hover", function () {
      $(this).toggleClass("hover", false);
    });

  // securityNumber
  $(".securityNumber")
    .find("input[type=text]").on("keyup", securityNumber).end()
    .find("input[type=checkbox]").on("change", securityNumber);
  securityNumber();

});

/* ------------------------------------------ Table Sorter ------------------------------ */

$.tablesorter.addParser({
  id: 'salesStatusClass',
  is: function (s) { return false; },
  format: function (s) {
    var a = [
      "yellowCell",
      "greenCell",
      "blueCell",
      "",
      "redCell",
      "purpleCell"
    ],
      cl = $(s).attr("data-sales-status-class");

    for (i = 0; i < a.length; i++) {
      if (cl == a[i])
        return i;
    }
    return a.length;
  },
  type: "numeric"
});


/* ------------------------------------------ General Functions ------------------------------ */

// Colorize Note
$.fn.colorNote = function () {
  $(this).each(function () {
    var v = parseInt($(this).val(), 10);
    var $td = $(this).closest("td");

    $td.removeClass("redCell yellowCell greenCell");

    if ($(this).attr("name") == "Status") {
      if (v == constants.NOTE_STATUS_OPEN)
        $td.addClass("redCell");
    }
    else if ($(this).attr("name") == "Type") {
      switch (v) {
        case constants.NOTE_TYPE_README:
          $td.addClass("yellowCell");
          break;
        case constants.NOTE_TYPE_SPECIAL_OFFER:
          $td.addClass("greenCell");
          break;
      }
    }
  });
}

// Security length
function securityNumber() {
  var okay = true;
  var intl = $(".securityNumber input:checkbox").is(":checked");
  $(".securityNumber input[type=text]").each(function (i) {
    if (intl) {
      if (i == 4 && $(this).val().length <= 2)
        okay = false;
    }
    else {
      if (i <= 2 && $(this).val().length < $(this).attr("maxlength"))
        okay = false;
    }
  });
  $(".securityNumber").css({ backgroundColor: okay ? "" : "yellow" });
}

// Select All Checkboxes
function selectAllInForm(selectAll, checkboxName, tableReference) {
  $(tableReference).find("input:checkbox[name=" + checkboxName + "]").prop("checked", selectAll);
}

/* ------------------------------------------ Company/Affiliate Notes ------------------------------ */

function loadAffiliateNotes(UUID, affiliateid){
  loadAccountNotes(UUID, "affiliate", affiliateid, 103);  // Start with README OR OPEN notes if there are any, otherwise show ALL: APPLICATION.CONSTANTS.NOTE_TYPE_GROUP_ALL
}

function loadCompanyNotes(UUID, companyid){
  loadAccountNotes(UUID, "company", companyid, 103); // Start with README OR OPEN notes if there are any, otherwise show ALL: APPLICATION.CONSTANTS.NOTE_TYPE_GROUP_ALL
}

function addAffiliateNote(UUID, affiliateid) {
  addAccountNote(UUID, 'affiliate', affiliateid);
}

function addCompanyNote(UUID, companyid) {
  addAccountNote(UUID, 'company', companyid);
}

function updateAffiliateNote(UUID, affiliateid, noteid, type, status) {
  updateAccountNote(UUID, "affiliate", affiliateid, noteid, type, status);
}

function updateCompanyNote(UUID, companyid, noteid, type, status) {
  updateAccountNote(UUID, "company", companyid, noteid, type, status);
}

function deleteAffiliateNote(UUID, affiliateid, noteid, type) {
  deleteAccountNote(UUID, "affiliate", affiliateid, noteid, type);
}

function deleteCompanyNote(UUID, companyid, noteid, type) {
  deleteAccountNote(UUID, "company", companyid, noteid, type);
}

/* ------------------------------------------ Ajax Functions ------------------------------ */

/**
 *  Responds to user clicking on the notes column in View Accounts.
 *
 *  @param {int} rowNum - row in View Accounts table
 *  @param {int} companyID - ID of company in the selected row, which is (always) a number
 *  @return void - Opens a row and calls functions to get data and fill the notes table. Or, closes and open row.
 *
 */
function toggleNotesRow(rowNum, noteType, accountid){
  // console.log('$("#note"+rowNum) after: ', $("#note"+rowNum));
  if($("#note"+rowNum).is(":visible")){
    $("#note"+rowNum).hide(); // the row is visible, so close and do nothing
  }
  else {
    loadAccountNotesSummary(rowNum, noteType, accountid); // row is NOT visible, so go open and load
  }
}

/**
 *  Loads notes of a given type on the View Accounts/Affiliates page
 *
 *  @param {int} rowNum - row in View Accounts/Affiliates table
 *  @param {string} noteType - the type of note the accountid relates to, for now 'company' or 'affiliate'
 *  @param {int} accountid - ID of the account in the selected row, which is (always) a number
 *  @return void - If the data is valid buildNotesRows() is called to render the notes table
 *
 */
function loadAccountNotesSummary(rowNum, noteType, accountid){
  var url = g.baseApiUrl + '/note/' + noteType.toLowerCase() + '/' + accountid;
  var headers = createBaseApiHeaders(getApiKeyFromStorage(),getApiTokenFromStorage());

  $.ajax({
    type: "GET",
    url: url,
    headers: headers,
    dataType: "json",
    success: function (result) {
      buildNotesRows(rowNum, result.Data.Data, result.Translations);
    },
    error: function (request, status, error) {
      console.log(error);
      $("#cell"+rowNum).empty();
      $("#note"+rowNum).show();
      $("#cell"+rowNum).append("<br><p>" + "There was an error. Please try again." + "</p><br>"); // TODO: i18n
    }
  });

}

/**
 *  Builds and appends a table of notes for a given company on the View Accounts page
 *
 *  @param {int} rowNum - row in View Accounts table
 *  @param {Object} notesData - data received containing all notes data to build out the table
 *  @return void - renders and appends the table to the open row
 *
 */
function buildNotesRows(rowNum, notesData, translations){
  $("#cell"+rowNum).empty();
  $("#note"+rowNum).show();
  // console.log("buildNotesRows(notesData) = ", notesData);

  var table = "<table cellpadding='3' cellspacing='0' class='dataTable nestedTable'>";
  table += '<thead><tr><th nowrap="nowrap" width="1%"><strong>';
  table += translations.DATE;
  table += '</strong></th><th nowrap="nowrap" width="1%"><strong>';
  table += translations.TYPE;
  table += '</strong></th><th nowrap="nowrap" width="1%"><strong>';
  table += translations.STATUS;
  table += '</strong></th><th nowrap="nowrap" width="1%"><strong>';
  table += translations.ADMIN;
  table += '</strong></th><th nowrap="nowrap" width="1%"><strong>';
  table += translations.USER;
  table += '</strong></th><th width="95%"><strong>';
  table += translations.NOTE;
  table += '</strong></th></tr></thead>';
  table += "<tbody>";
  for(var i = 0; i < notesData.length; i++){
    var statusClass = (notesData[i].STATUS == 1) ? "open" : "resolved";
    var row = "<tr class='" + statusClass + "'>";
    row += "<td nowrap='nowrap' width='1%'>" + notesData[i].DATEPOSTEDLOCALFMT + "</td>";
    row += "<td nowrap='nowrap' width='1%'class='" + notesData[i].DISPLAYSTATUSCLASS + "'>" + notesData[i].NOTETYPESTRING + "</td>";
    row += "<td nowrap='nowrap' width='1%'>" + notesData[i].NOTESTATUSSTRING + "</td>";
    row += "<td nowrap='nowrap' width='1%'>" + notesData[i].SITEADMINFULLNAME + "</td>";

    var userName = "--";
    if (typeof notesData[i].USERFULLNAME !== 'undefined' && notesData[i].USERFULLNAME !== "") {
      userName = notesData[i].USERFULLNAME;
    }
    else if (typeof notesData[i].AFFILIATEFULLNAME !== 'undefined' && notesData[i].AFFILIATEFULLNAME !== "") {
      userName = notesData[i].AFFILIATEFULLNAME;
    }

    row += "<td nowrap='nowrap' width='1%'>" + userName + "</td>";
    row += "<td width='95%'>" + notesData[i].NOTE + "</td>";
    row += "</tr>";
    table += row;
  }
  table += "</tbody>";
  table += "</table>";
  table += "<p>* Notes listed in RED are currently open/unresolved</p>";
  // console.log("table = ", table);
  $("#cell"+rowNum).append(table);
}

/**
 * Load Account Notes data via the API
 *
 * @param {String} UUID - not sure if we're ultimately going to use UUID?
 * @param {String} notetype - affiliate or company
 * @param {int} accountid - company id
 * @param {int} type - 0 = General, 2 = Readme, etc.
 * @param {int} status - 1 = open, 0 = closed
 * @return void - calls constructNotesTable() to build out the html table
 */
function loadAccountNotes(UUID, notetype, accountid, type, status) {
  //console.log("%c loadAccountNotes()", "color:blue;font-weight:bold;"," uuid = ", UUID, ", notetype = ", notetype, ", type = ", type, ", status = ", status);
  var url = g.baseApiUrl + '/note/' + globalCompanyType + '/' + accountid; // + '/' + UUID;
  // console.log("%c loadAccountNotes(), url = ","color:green;font-weight:bold;", url);
  var headers = createBaseApiHeaders(getApiKeyFromStorage(),getApiTokenFromStorage());

  var dataObj = new Object();

  if ( notetype.toLocaleLowerCase() === "affiliate" ){
    dataObj.AffiliateID = accountid;
  }
  else if ( notetype.toLocaleLowerCase() === "company" ){
    dataObj.CompanyID = accountid;
  }
  if(status){
    dataObj.Status = status;
  }
  if(type){
    dataObj.Type = type;
  }

  $.ajax({
    type: "GET",
    url: url,
    data: (dataObj),
    dataType: "text",
    cache: false,
		headers: headers,
    success: function (d) {
      let resp = JSON.parse(d);
      constructNotesTable(resp.Data.Data, notetype);

      $(".AccountNotesTable").css("border", "none");
      $("div.AccountNotesTable[data-uuid=" + UUID + "]")
        .animate({ opacity: 0 }, 100, function () {
          $(this)
            .css({ opacity: 0 })
            .html(resp)
            .animate({ opacity: 1 }, 100, function () {
              $(this)
                .css({ opacity: 1, zoom: 1 })
                .find("select")
                .colorNote();
            });
        });
      setSelected(resp.Type);
    },
    error: function (request, status, error) {
      console.log(error);
      alert("There was an error. Please try again."); // TODO: i18n
    }
  });
}

// AJAX: Add Note
function addAccountNote(UUID, notetype, accountid) {

	var url = g.baseApiUrl + '/note/' + notetype.toLowerCase() + '/' + accountid;

  var $sel = $("div.AccountNoteEntryDiv[data-id=" + UUID + "]")
  var dataObj = new Object();
  switch (notetype.toUpperCase()) {
    case "AFFILIATE":
      dataObj.AffiliateID = accountid;
      break;
    case "COMPANY":
      dataObj.CompanyID = accountid;
      break;
  }
  dataObj.Type = $sel.find("select[name=Type]").val();
  dataObj.Status = $sel.find("select[name=Status]").val();
  dataObj.Note = $sel.find("textarea[name=Note]").val();

  $.ajax({
    type: "POST",
    url: url,
    data: (dataObj),
    dataType: "json",
    cache: false,
		headers: createBaseApiHeaders(getApiKeyFromStorage(),getApiTokenFromStorage()),
    success: function (d) {
      if (d.MsgType == "CONFIRMATION") {
        $sel
          .css({ opacity: 0 })
          .find("textarea[name=Note]").val("").end()
          .animate({ opacity: 1 }, 200, function () {

            // set the notes and selected to the value of new note
            // loadAccountNotes(UUID, notetype, accountid, dataObj.Type);
            // setSelected(dataObj.Type);

            // set the notes and selected to all after adding a new note
            loadAccountNotes(UUID, notetype, accountid, 102);
            setSelected(102);

          });
      }
      else {
        alert("ERROR: " + d.Msg);
      }
    },
    error: function (request, status, error) {
      console.log(error);
      alert("There was an error. Please try again."); // TODO: i18n
    }
  });
}

function updateAccountNote(UUID, notetype, accountid, noteid, type, status) {

  var url = g.baseApiUrl + '/note/' + notetype.toLowerCase() + '/' + accountid + '/' + noteid;
  var typeSelect = $('#typeSelect').find(":selected").val();

  var dataObj = new Object();
  switch (notetype.toUpperCase()) {
    case "AFFILIATE":
      dataObj.AffiliateID = accountid;
      break;
    case "COMPANY":
      dataObj.CompanyID = accountid;
      break;
  };
  dataObj.NoteID = noteid;
  dataObj.Type   = type;
  dataObj.Status = status;

  $.ajax({
    type: "PATCH",
    url: url,
    data: (dataObj),
    dataType: "json",
    cache: false,
    headers: createBaseApiHeaders(getApiKeyFromStorage(),getApiTokenFromStorage()),
    success: function (d) {
      if (d.MsgType == "CONFIRMATION"){
        loadAccountNotes(UUID, notetype, accountid, typeSelect);
        setSelected(typeSelect);
      }
      else
        alert("ERROR: " + d.Msg);
    },
    error: function (request, status, error) {
      console.log(error);
      alert("There was an error. Please try again."); // TODO: i18n
    }
  });
}

function deleteAccountNote(UUID, notetype, accountid, noteid, type) {

  var url = g.baseApiUrl + '/note/' + notetype.toLowerCase() + '/' + accountid + '/' + noteid;
  var typeSelect = $('#typeSelect').find(":selected").val();

  var dataObj = new Object();
  switch (notetype.toUpperCase()) {
    case "AFFILIATE":
      dataObj.AffiliateID = accountid;
      break;
    case "COMPANY":
      dataObj.CompanyID = accountid;
      break;
  };
  dataObj.NoteID = noteid;

  $.ajax({
    type: "DELETE",
    url: url,
    data: (dataObj),
    dataType: "json",
    cache: false,
    headers: createBaseApiHeaders(getApiKeyFromStorage(),getApiTokenFromStorage()),
    success: function (d) {
      if (d.MsgType == "CONFIRMATION"){
        loadAccountNotes(UUID, notetype, accountid, typeSelect);
        setSelected(typeSelect);
      }
      else
        alert("ERROR: " + d.Msg);
    },
    error: function (request, status, error) {
      console.log(error);
      alert(getTranslation("JavaScript.PleaseTryAgain"));
    }
  });
}

/**
 * Builds the table for Notes from raw data
 *
 * @param {Object} notesData - json data retrieved via the api
 * @param {String} notetype - affiliate or company
 * @returns void - appends the finished html table to the requesting page
 */
function constructNotesTable(notesData, notetype){
  $("#notesTableWrapper").empty();
  // console.log("%c constructNotesTable(), data = ", "color:green;font-weight:bold;", notesData);
  let companyID = $("#AccountNotesTable").attr("data-accountid");
  // Header titles
  let headingPosted = "Posted";   // TODO: i18n   Label.Posted
  let headingUpdated = "Updated"; // TODO: i18n   Label.Updated
  let headingAdmin = "Admin";     // TODO: i18n   Label.Admin
  let headingUser = "User";       // TODO: i18n   Label.User
  let headingType = "Type";       // TODO: i18n   Label.Type
  let headingStatus = "Status";   // TODO: i18n   Label.Status
  let headingNote = "Note";       // TODO: i18n   Label.Note
  let labelNone = "None";         // TODO: i18n   Label.None
  let deleteConfirm = "Permanently delete this item?";  // TODO: i18n

  var table = '<table width="100%" border="0" cellspacing="0" cellpadding="0" class="dataTable" id="accountNotesListTable">';
  table += '<thead><tr>';
  table +=    '<th>'+headingPosted+'</th>';
  table +=    '<th>'+headingUpdated+'</th>';
  table +=    '<th>'+headingAdmin+'</th>';
  if(globalCompanyType == "company"){
    table +=    '<th>'+headingUser+'</th>';
  }
  table +=    '<th>'+headingType+'</th>';
  table +=    '<th>'+headingStatus+'</th>';
  table +=    '<th>'+headingNote+'</th>';
  table +=    '<th></th>';
  table += '</tr></thead>'
  table += '<tbody>';
  if(notesData.length <= 0){
    table += '<tr><td colspan="8"><span class="none-formatting">'+labelNone+'</span></td></tr>';
  }
  else {
    for(var index = 0; index < notesData.length; index++){
      var rowClass = "odd other-notes";
      if(index % 2 == 0){
        rowClass = "even2 readme-notes"
      }

      table += '<tr data-id="admin_'+companyID+'" data-noteid="'+notesData[index].NOTEID+'" data-type="Company" data-accountid="'+companyID+'" class="'+rowClass+'">';
      table +=    "<td valign='top'>" + notesData[index].DATEPOSTEDLOCALFMT + "</td>";
      table +=    "<td valign='top'>" + notesData[index].DATEUPDATED + "</td>";
      table +=    "<td valign='top'>" + notesData[index].SITEADMINFULLNAME + "</td>";
      if(globalCompanyType == "company"){
        table +=    "<td valign='top'>" + notesData[index].USERFULLNAME + "</td>";
      }
      table +=    "<td valign='top' class='type-col " + notesData[index].DISPLAYSTATUSCLASS + "'>" + constructNoteTypeDropdown(notesData[index].TYPE) + "</td>";
      table +=    "<td valign='top' class='status-col " + notesData[index].DISPLAYDATECLASS + "'>" + constructStatusDropdown(notesData[index].STATUS) + "</td>";
      table +=    "<td valign='top'>" + notesData[index].NOTE + "</td>";
      table +=    '<td class="delete-col">';
      if(notesData[index].DELETEPERMISSION === "true" ){
        var confirmDialogArgs = "";
        if(globalCompanyType == "company"){
          confirmDialogArgs = "{msg:'"+deleteConfirm+"', okay: function() { deleteCompanyNote('"+notesData[index].NOTESTATUSSTRING+"',"+companyID+","+notesData[index].NOTEID+", " + notesData[index].TYPE + "); },stayOpen:false}";
        }
        else {
          confirmDialogArgs = "{msg:'"+deleteConfirm+"', okay: function() { deleteAffiliateNote('"+notesData[index].NOTESTATUSSTRING+"',"+companyID+","+notesData[index].NOTEID+", " + notesData[index].TYPE + "); },stayOpen:false}";
        }
        table +=    '<a href="javascript:void(0);" onclick="confirmDialog('+confirmDialogArgs+')"><i class="fas font-red fa-trash-alt" aria-hidden="true"></i></a></i>';
      }
      table +=    "</td>";

      table += "</tr>";
    }
  }
  table += "</tbody></table>";
  $("#notesTableWrapper").append(table);
}

/**
 * Sets up filters (tabs) on the notes table, including dropdown for note types
 *
 * @return void - appends the filters above the table
 */
function constructFilters(selectedType){
  $("#AccountNotesFilters").empty();  // out with the old...

  var filterLabel = getTranslation("Content.Label.FilterBy");
  var filterSelect = '<div id="admin-notes-buttons">';

  let filtersList = constructTypeArray(true,true,( globalCompanyType == "company") );
  var dropdown = '<select name="Type" size="1" class="preserve" id="typeSelect">';
  for(var i = 0; i < filtersList.length; i++){
    var selectedStatus = (selectedType == filtersList[i].constType) ? ' selected="selected"' : '';
    dropdown += '<option value="'+filtersList[i].constType+'"'+selectedStatus+'>'+filtersList[i].text+'</option>';
  }
  dropdown += '</select>';
  filterSelect += filterLabel + ':&nbsp;' + dropdown;
  filterSelect += '</div>';

  $("#AccountNotesFilters").append(filterSelect);

}

/**
 * Builds a dropdown of Note Types
 * @param {int} selectedType - based on site Constants, where null or other value means none will be selected
 * @returns {html} dropdown - html select list
 */
function constructNoteTypeDropdown(selectedType, id){
  let typesList = constructTypeArray(false,true,( globalCompanyType == "company") );
  var dropdown = '<select name="Type" size="1" class="preserve" id="'+id+'">';
  for(var i = 0; i < typesList.length; i++){
    var selectedStatus = (selectedType == typesList[i].constType) ? ' selected="selected"' : '';
    dropdown += '<option value="'+typesList[i].constType+'"'+selectedStatus+'>'+typesList[i].text+'</option>';
  }
  dropdown += '</select>';
  return dropdown;
}

/**
 * Creates a dropdown with Open & Closed status (the only two so far)
 *
 * @param {int} selectedStatus - set from Constants, but 1 = open, 0 = closed
 * @returns stat - html containing the dropdown with selected status
 */
function constructStatusDropdown(selectedStatus){
  let constStatusOpen = 1;        // TODO: APPLICATION.CONSTANTS.NOTE_STATUS_OPEN
  let constStatusClosed = 0;      // TODO: APPLICATION.CONSTANTS.NOTE_STATUS_CLOSED
  let textOpen = "Open";          // TODO: i18n
  let textClosed = "Closed";      // TODO: i18n
  let selectedOpen = (selectedStatus == constStatusOpen) ? 'selected="selected"' : '';
  let selectedClosed = (selectedStatus == constStatusClosed) ? 'selected="selected"' : '';
  var stat = '<select name="Status" size="1" class="preserve">';
  stat += '<option value="'+constStatusOpen+'" '+selectedOpen+'>'+textOpen+'</option>';
  stat += '<option value="'+constStatusClosed+'" '+selectedClosed+'>'+textClosed+'</option>';
  stat += '</select>';
  return stat;
}

/**
 * Sets up an array containing all Note Types with text and ID number
 *
 * @returns {Array} - Array of Note Type Objects
 */
function constructTypeArray(includeGroups,includeTypes,includeExtended){
  let groupsList = [
    {
      text: "README or OPEN",    // TODO: i18n
      constType: 103 // TODO: APPLICATION.CONSTANTS.NOTE_TYPE_GROUP_READMEOROPEN
    },
    {
      text: "README and OPEN",    // TODO: i18n
      constType: 101 // TODO: APPLICATION.CONSTANTS.NOTE_TYPE_GROUP_CUSTSRV
    },
    {
      text: "ANY OPEN",    // TODO: i18n
      constType: 104 // TODO: APPLICATION.CONSTANTS.NOTE_TYPE_GROUP_ANYOPEN
    },
    {
      text: "ALL NOTES",    // TODO: i18n
      constType: 102 // TODO: APPLICATION.CONSTANTS.NOTE_TYPE_GROUP_ALL
    }
  ];
  let typesList = [
    {
      text: "General",    // TODO: i18n
      constType: 0 // TODO: APPLICATION.CONSTANTS.NOTE_TYPE_GENERAL
    },
    {
      text: "Read Me",  // TODO: i18n
      constType: 2 // TODO: APPLICATION.CONSTANTS.NOTE_TYPE_README
    },
    {
      text: "Customer Comment",  // TODO: i18n
      constType: 4 // TODO: APPLICATION.CONSTANTS.NOTE_TYPE_CUSTOMER_COMMENT
    }];
  let extendedList = [
    {
      text: "Special Offer",  // TODO: i18n
      constType: 3 // TODO: APPLICATION.CONSTANTS.NOTE_TYPE_SPECIAL_OFFER
    },
    {
      text: "Retention",  // TODO: i18n
      constType: 1 // TODO: APPLICATION.CONSTANTS.NOTE_TYPE_RETENTION
    },
    {
      text: "Customer Suggestion",  // TODO: i18n
      constType: 8 // TODO: APPLICATION.CONSTANTS.NOTE_TYPE_CUSTOMER_SUGGESTION
    },
    {
      text: "Billing Related",  // TODO: i18n
      constType: 5 // TODO: APPLICATION.CONSTANTS.NOTE_TYPE_BILLING_RELATED
    },
    {
      text: "Trial Comment",  // TODO: i18n
      constType: 9 // TODO: APPLICATION.CONSTANTS.NOTE_TYPE_TRIAL_COMMENT
    },
    {
      text: "Enrollment Info",  // TODO: i18n
      constType: 7 // TODO: APPLICATION.CONSTANTS.NOTE_TYPE_ENROLLMENT_INFO
    },
    {
      text: "Bug Report",  // TODO: i18n
      constType: 6 // TODO: APPLICATION.CONSTANTS.NOTE_TYPE_BUG_REPORT
    },
    {
      text: "No Sale",  // TODO: i18n
      constType: 11 // TODO: APPLICATION.CONSTANTS.NOTE_TYPE_NO_SALE
    },
    {
      text: "Demo Comment",  // TODO: i18n
      constType: 12 // TODO: APPLICATION.CONSTANTS.NOTE_TYPE_DEMO_COMMENT
    },
  ];

  let list = [];
  if ( includeGroups )
    list = list.concat(groupsList);
  if ( includeTypes )
    list = list.concat(typesList);
  if ( includeExtended )
    list = list.concat(extendedList);

  return list;

}

/**
 * Which tab is selected? Read Me, General, etc
 * @param {*} id - "id" of button clicked (open, all, or numeric for readme, general)
 */
function setSelected(id){

  $("#typeSelect").val(id);

}

// Init "global" variables, define when the doc is loaded
var globalCompanyType = "";

$(document).ready(function () {

  globalCompanyType = ($("#AccountNotesTable").length) ? $("#AccountNotesTable").attr("data-type").toLowerCase() : "company";

  constructFilters(101);

  // When tabs or dropdown are clicked (read me vs general etc)
  $("#AccountNotesFilters").on("change", "select", function (event) {
    loadAccountNotes($("#AccountNotesFilters").attr("data-uuid"), globalCompanyType, $("#AccountNotesFilters").attr("data-accountid"), event.target.value);
    setSelected(event.target.value);
  });
});
